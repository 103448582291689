import React, { useState } from 'react';
import {
    Box,
    Button,
    Input,
    Select,
    VStack,
    HStack,
    IconButton,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    Heading,
    Textarea
} from '@chakra-ui/react';
import { CloseIcon, EditIcon } from '@chakra-ui/icons'

interface FormData {
    type: string;
    value: string;
    placeholder: string;
    options: string;
}

const DynamicForm: React.FC = () => {
    const [formData, setFormData] = useState<FormData[]>([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [ObjectInfo, setObjectInfo] = useState<FormData>({
        type: "input",
        value: "",
        placeholder: "",
        options: ""
    })
    const [buttonText, setButtonText] = useState("Send Data");
    const [redactText, setRedactText] = useState("");

    const handleRemoveField = (index: number) => {
        const updatedData = formData.filter((_, i) => i !== index);
        setFormData(updatedData);
    };

    const handleInputChange = (index: number, value: string) => {
        const updatedData = [...formData];
        updatedData[index].value = value;
        setFormData(updatedData);
    };

    const handleChange = ({ target }: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >) => {
        const { name, value } = target;
        setObjectInfo((prevData) => ({
            ...prevData,
            value: '',
            [name]: value,
        }));
    };

    return (
        <HStack spacing={8}>
            <VStack>
                <Drawer isOpen={isDrawerOpen} placement="right" onClose={() => setIsDrawerOpen(false)}>
                    <DrawerOverlay>
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>Add Fields</DrawerHeader>
                            <DrawerBody>
                                <VStack spacing={4}>
                                    <Select onChange={handleChange} name="type" value={ObjectInfo.type}>
                                        <option value="input">Input</option>
                                        <option value="select">Select</option>
                                    </Select>
                                    <Input onChange={handleChange} name="placeholder" value={ObjectInfo.placeholder} placeholder="Placeholder" />
                                    <Textarea disabled={ObjectInfo.type === 'input'} onChange={handleChange} name="options" placeholder="write options using ','" />
                                    <Button onClick={() => setFormData([...formData, Object.assign({}, ObjectInfo)])}>Add Object</Button>
                                </VStack>
                            </DrawerBody>
                            <DrawerHeader>Change Button Text</DrawerHeader>
                            <DrawerBody>
                                <VStack spacing={4}>
                                    <Input value={redactText} onChange={(e: any) => setRedactText(e.target.value)} placeholder="title" />
                                    <Button onClick={() => setButtonText(redactText)}>Replace</Button>
                                </VStack>
                            </DrawerBody>
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>
            </VStack>
            <Box className="constructor-Container">
                <Box className="constructor-Header">
                    <Heading size="lg">Demo-Form</Heading>
                    <IconButton
                        icon={<EditIcon />}
                        aria-label="Edit"
                        onClick={() => setIsDrawerOpen(true)}
                    />
                </Box>
                {formData.map((field, index) => (
                    <Box key={index} className="constructorRow">
                        {field.type === 'input' && (
                            <Input
                                placeholder={field.placeholder}
                                value={field.value}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                            />
                        )}
                        {field.type === 'select' && (
                            <Select
                                placeholder={field.placeholder}
                                value={field.value}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                            >
                                {field.options.split(',').map(option => (
                                    <option value={option}>{option}</option>
                                ))}
                            </Select>
                        )}
                        <IconButton
                            icon={<CloseIcon />}
                            aria-label="Remove"
                            onClick={() => handleRemoveField(index)}
                        />
                    </Box>
                ))}
                <Button>{buttonText}</Button>
            </Box>
        </HStack >
    );
};

export default DynamicForm;
