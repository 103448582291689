import { Box, Input, Select, Textarea, Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import useTelegram from "../utils/hooks/useTelegram";
import formatNumberWithCommas from "../utils/helpers/formatNumberWithCommas";
import CashService from "../utils/services/cashflow.service";

interface Income {
    accounts: string[],
    categories: string[],
    recipients: string[],
}

const Income = ({ accounts, categories, recipients }: Income) => {
    const today = new Date().toISOString().substr(0, 10);
    const toast = useToast();
    const { user } = useTelegram();
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        sum: "",
        date: today,
        account: "",
        category: "",
        recipient: "",
        comment: "",
        operation: "income",
        user: user?.id
    });

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = target;
        const newValue = name === "sum" ? parseFloat(value.replace(/,/g, '')) : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue
        }));
    };

    const handleSubmit = async () => {
        if (!formData.sum || !formData.account || !formData.category) {
            toast({
                title: "Заполните все поля",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);

        try {
            const response = await CashService.sendData(formData);
            toast({
                title: response.message,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            resetForm();
        } catch (error : any) {
            console.error("Error:", error);
            toast({
                title: error.message,
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setFormData({
            sum: "",
            date: today,
            account: "",
            category: "",
            recipient: "",
            comment: "",
            operation: "income",
            user: user?.id
        });
    }

    return (
        <Box className="input-form">
            <Input
                type="text"
                name="sum"
                inputMode="decimal"
                pattern="[0-9]*"
                value={formatNumberWithCommas(formData.sum)}
                onChange={handleChange}
                placeholder="Сумма"
            />
            <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
            />
            <Select
                name="account"
                value={formData.account}
                onChange={handleChange}
            >
                <option value="">Счёт</option>
                {accounts?.map(account => (
                    <option value={account} key={account} >{account}</option>
                ))}
            </Select>
            <Select
                name="category"
                value={formData.category}
                onChange={handleChange}
            >
                <option value="">Категория</option>
                {categories?.map(category => (
                    <option value={category} key={category} >{category}</option>
                ))}
            </Select>
            <Select
                name="recipient"
                value={formData.recipient}
                onChange={handleChange}
            >
                <option value="">Получатель</option>
                {recipients?.map(recipient => (
                    <option value={recipient} key={recipient} >{recipient}</option>
                ))}
            </Select>
            <Textarea
                rows={4}
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                placeholder="Комментарий..."
            />
            <Button isLoading={isLoading} onClick={handleSubmit}>Отправить</Button>
        </Box>
    );
};

export default Income;
