/**
 * Return sum with decarators
 * @param number 
 * @returns 
 */
const formatNumberWithCommas = (number: string | number) => {
  if (!number) return "";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default formatNumberWithCommas;
