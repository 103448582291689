import { BrowserRouter, Routes, Route } from "react-router-dom";
import CashFlow from "./pages/CashFlow";
import HourForm from "./pages/HourForm";
import Constructor from "./pages/Constructor";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/cashflow" element={<CashFlow />} />
        <Route path="/" element={<HourForm />} />
        <Route path="/constructor" element={<Constructor />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;