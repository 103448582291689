import { Box, Tabs, TabList, Tab, useToast, TabPanels, TabPanel } from '@chakra-ui/react'
import Expense from '../components/Expense'
import Income from '../components/Income'
import Transfer from '../components/Transfer'
import { useState, useEffect } from 'react'
import CashService from "../utils/services/cashflow.service";
import useTelegram from '../utils/hooks/useTelegram'

const CashFlow = () => {
    const { tg, user } = useTelegram();
    const toast = useToast();
    const [data, setData] = useState({
        accounts: [],
        categories: [],
        recipients: [],
        expense: []
    })

    useEffect(() => {
        CashService.getMeta(user.id)
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                toast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
                setTimeout(() => {
                    tg.close();
                }, 3000);
            });
    }, []);

    return (
        <Box>
            <Tabs className='page'>
                <TabList className='navigation'>
                    <Tab>Приход</Tab>
                    <Tab>Расход</Tab>
                    <Tab>Перевод</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Income {...data} />
                    </TabPanel>
                    <TabPanel>
                        <Expense {...data} />
                    </TabPanel>
                    <TabPanel>
                        <Transfer accounts={data.accounts} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}

export default CashFlow;
