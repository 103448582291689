import { ICashData, ITransferData } from "../interfaces/ICashData";
import $fetch from "../config/axios.config";

const CashService = {
  /**
   * Service to get accounts, recepients
   * @returns data
   */
  async getMeta(id: string) {
    try {
      const response = await $fetch.get(`/cashflow/meta?telegram_id=${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meta:", error);
      throw error;
    }
  },

  /**
   * Service to send Data
   * @param data
   * @returns
   */
  async sendData(data: ITransferData | ICashData) {
    try {
      const response = await $fetch.post("/cashflow/", data, {
        headers: {
          telegram_id: data.user,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error sending data:", error);
      throw error;
    }
  },
};

export default CashService;
