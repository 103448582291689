/** @format */

import axios from "axios";

const $fetch = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 15000,
});

export default $fetch;
