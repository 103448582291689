import { Box, Input, useToast, Select, Textarea, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import useTelegram from "../utils/hooks/useTelegram";
import formatNumberWithCommas from "../utils/helpers/formatNumberWithCommas";
import CashService from "../utils/services/cashflow.service";

const Transfer = ({ accounts }: { accounts: string[] }) => {
    const today = new Date().toISOString().substr(0, 10);
    const toast = useToast();
    const { user } = useTelegram();
    const [isLoading, setIsLoading] = useState(false);

    const [transferData, setTransferData] = useState({
        fromAccount: "",
        sum: "",
        sum2: "",
        toAccount: "",
        date: today,
        comment: "",
        operation: "transfer",
        user: user?.id
    });

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = target;
        const newValue = name === "sum" || name === "sum2" ? parseFloat(value.replace(/,/g, '')) : value;
        setTransferData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue
        }));
    };

    const handleSubmit = async () => {
        if (!transferData.sum || !transferData.fromAccount || !transferData.toAccount) {
            toast({
                title: "Заполните все поля",
                status: 'warning',
                duration: 2000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true)

        try {
            const response = await CashService.sendData(transferData);
            toast({
                title: response.message,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            resetForm();
        } catch (error: any) {
            console.error("Error:", error);
            toast({
                title: error.message,
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setTransferData({
            fromAccount: "",
            sum: "",
            sum2: "",
            toAccount: "",
            date: today,
            comment: "",
            operation: "transfer",
            user: user?.id
        });
    }

    useEffect(() => {
        if (!(transferData.fromAccount.includes("USD") || transferData.toAccount.includes("USD"))) {
            setTransferData((prevFormData) => ({
                ...prevFormData,
                sum2: transferData.sum
            }));
        }
    }, [transferData])

    return (
        <Box className="input-form">
            <Select
                name="fromAccount"
                value={transferData.fromAccount}
                onChange={handleChange}
            >
                <option value="">Со счёта</option>
                {accounts?.map(account => (
                    <option value={account} key={account} >{account}</option>
                ))}
            </Select>
            <Input
                type="text"
                name="sum"
                inputMode="decimal"
                pattern="[0-9]*"
                value={formatNumberWithCommas(transferData.sum)}
                onChange={handleChange}
                placeholder="Сумма"
            />
            <Select
                name="toAccount"
                value={transferData.toAccount}
                onChange={handleChange}
            >
                <option value="">На счёт</option>
                {accounts?.map(account => (
                    <option value={account} key={account} >{account}</option>
                ))}
            </Select>
            <Input
                type="text"
                name="sum2"
                disabled={!(transferData.fromAccount.includes("USD") || transferData.toAccount.includes("USD"))}
                inputMode="decimal"
                pattern="[0-9]*"
                value={formatNumberWithCommas(transferData.sum2)}
                onChange={handleChange}
                placeholder="Сумма"
            />
            <Input
                name="date"
                type="date"
                value={transferData.date}
                onChange={handleChange}
            />
            <Textarea
                rows={4}
                name="comment"
                value={transferData.comment}
                onChange={handleChange}
                placeholder="Комментарий"
            />
            <Button isLoading={isLoading} onClick={handleSubmit}>Отправить</Button>
        </Box>
    );
};

export default Transfer;
